import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import { auth, signInWithEmailPassword } from "../../../firebase";
import PinBox from "../Passwrod/PinBox";
import FullPageLoader from "../../Common/FullPageLoader";
import { SESSION_AUTH } from "../../../helper/auth";
import { isValidEmail } from "../../../helper/constants";

const LoginWithPassword = props => {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin, setPasswordLogin, email } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [pin, setPin] = useState("");

  const login = async () => {
    if (!email || (email && !isValidEmail(email))) {
      return toast("Enter your email address");
    } else if (!pin || pin?.length < 4) {
      return toast("Enter your PIN");
    } else {
      setLoader(true);
      const data = await signInWithEmailPassword(email, pin);

      if (data?.code === 200 && data?.data) {
        SET_USER_SESSION(data?.data);
        if (handleLogin) handleLogin();
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast(error);
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageLoader fixed={true} />}

      <div className="userLogin-email custom-form">
        <FormControl className="custom-form-group">
          <PinBox updatePin={setPin} />
        </FormControl>

        <button
          type="button"
          className="userLogin-email-btn mt-20"
          onClick={login}
          disabled={loader || loading}
        >
          Continue
        </button>

        <div className="userLogin-email-policy mt-20 mb-20">
          Clicking continue confirms you accept 6ix&apos;s{" "}
          <a
            href="https://6ix.com//privacy-policy"
            className="text-link"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Notice.
          </a>
        </div>

        <div className="userLogin-social">
          <div
            className="userLogin-email-link"
            onClick={() => {
              setPasswordLogin(3);
            }}
            role="button"
            tabIndex="0"
          >
            Reset PIN
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginWithPassword;
