import { apiCall } from "./servicesCall";

export const USER_SERVICES = {
  loginEmail(data) {
    return apiCall("/api/v1/auth/login/email", data);
  },
  loginUser(data) {
    return apiCall("/api/v1/auth/login", data);
  },
  getUser(data) {
    return apiCall("/api/v1/auth/user", data);
  },
  logoutUser(data) {
    return apiCall("/api/v1/auth/logout", data);
  },
  switchAccount(data) {
    return apiCall("/api/v1/account/switch", data);
  },
  createAccount(data) {
    return apiCall("/api/v1/account/create", data);
  },
  getAccountList(data) {
    return apiCall("/api/v1/account/list", data);
  }
};
