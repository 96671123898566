import { useState, useEffect } from "react";
import LoginWithPassword from "./LoginWithPassword";
import LoginWithEmail from "./LoginWithEmail";
import ForgotPassword from "./ForgotPassword";
import { SESSION_AUTH } from "../../../helper/auth";
import { Cross } from "../../../icons";
import "./Login.scss";

const LoginPopup = props => {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, dropOut } = props;

  const [passwordLogin, setPasswordLogin] = useState(0);

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (isUserSession) {
      handleLogin();
    }
  }, [isUserSession, handleLogin]);

  return (
    <>
      <div
        className="ai6ix-popup ai6ix-popup-xs userLogin"
        onClick={() => {
          if (dropOut) {
            handleLogin();
          }
        }}
        role="button"
        tabIndex="0"
      >
        <div
          className="ai6ix-popup-inner"
          onClick={e => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex="0"
        >
          <div
            role="button"
            tabIndex="0"
            className="ai6ix-popup-close"
            onClick={handleLogin}
          >
            <Cross />
          </div>

          {email && passwordLogin === 2 ? (
            <>
              <div className="ai6ix-popup-header">
                <div className="ai6ix-popup-header-heading">
                  Enter your 4-digit PIN
                </div>
                <div className="ai6ix-popup-header-desc"></div>
              </div>
              <div className="ai6ix-popup-content">
                <LoginWithPassword
                  setPasswordLogin={setPasswordLogin}
                  handleLogin={handleLogin}
                  setEmail={setEmail}
                  email={email}
                />
              </div>
            </>
          ) : email && (passwordLogin === 3 || passwordLogin === 4) ? (
            <>
              <div className="ai6ix-popup-header">
                <div className="ai6ix-popup-header-heading">Reset PIN</div>
                <div className="ai6ix-popup-header-desc">
                  {passwordLogin === 3 ? (
                    <> We will send you a link to reset your PIN </>
                  ) : null}
                </div>
                <div className="ai6ix-popup-header-desc"></div>
              </div>
              <div className="ai6ix-popup-content">
                <ForgotPassword
                  passwordLogin={passwordLogin}
                  email={email}
                  setEmail={setEmail}
                  setPasswordLogin={setPasswordLogin}
                />
              </div>
            </>
          ) : (
            <>
              <div className="ai6ix-popup-header">
                <div className="ai6ix-popup-header-heading">
                  Enter your email
                </div>
                <div className="ai6ix-popup-header-desc"></div>
              </div>
              <div className="ai6ix-popup-content">
                <LoginWithEmail
                  setPasswordLogin={setPasswordLogin}
                  handleLogin={handleLogin}
                  setEmail={setEmail}
                  email={email}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPopup;
