import { toast } from "react-toastify";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  signOut,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from "firebase/auth";
import { USER_SERVICES } from "./services";

const firebaseConfig = {
  apiKey: "AIzaSyA7yb0AANWCaBiIDk5kpH-lyQeHeOtcLgM",
  authDomain: "authentication.6ix.com",
  projectId: "six-events",
  storageBucket: "six-events.appspot.com",
  messagingSenderId: "215382211252",
  appId: "1:215382211252:web:b3bbf8418e33745faf75e1",
  measurementId: "G-YGEEQET0LL"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);
const googleProvider = new GoogleAuthProvider({
  prompt: "select_account"
});

const appleProvider = new OAuthProvider("apple.com");

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email
      };

      let data = await USER_SERVICES.loginUser(DATA);

      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        toast("You are now logged in");
        return { code: 200, data: d };
      } else {
        if (data.errors) {
          for (const property in data.errors) {
            toast(
              data.errors[property]?.[0] || "Error : Something went wrong!"
            );
          }
        } else {
          toast(data?.message || "Error : Something went wrong!");
        }
        return false;
      }
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithApple = async () => {
  try {
    await signInWithPopup(auth, appleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email
      };
      let data = await USER_SERVICES.loginUser(DATA);
      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        toast("You are now logged in");
        return { code: 200, data: d };
      } else {
        if (data.errors) {
          for (const property in data.errors) {
            toast(
              data.errors[property]?.[0] || "Error : Something went wrong!"
            );
          }
        } else {
          toast(data?.message || "Error : Something went wrong!");
        }
        return false;
      }
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithUserEmailLink = async email => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast("An email with the link has been sent to " + email);
        return "An email with the link has been sent to " + email;
      })
      .catch(err => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithEmailPassword = async (email, password, reset) => {
  try {
    return signInWithEmailAndPassword(
      auth,
      email,
      email?.slice(0, 2) + password
    )
      .then(async userCredential => {
        if (auth.currentUser) {
          const user = auth.currentUser;

          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
            reset: reset
          };
          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;

            toast("You are now logged in");
            return { code: 200, data: d };
          } else {
            toast(data.message);
            return false;
          }
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch(async error => {
        toast(errorSplit(error.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const userLogout = user => {
  signOut(auth);
  if (user?.userId) {
    USER_SERVICES.logoutUser({ userId: user?.userId, org: user?.org?.id });
  }
};

const sendForgotPasswordEmail = async email => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast("An email with the link has been sent to " + email);
        return true;
      })
      .catch(err => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const errorSplit = error => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
    error = error.replace(/password/g, "PIN");
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  userLogout,
  signInWithApple,
  sendForgotPasswordEmail,
  signInWithEmailPassword
};
